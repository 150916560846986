import React from 'react'
import Titulo from '../Titulo'
import ImagemNovidades from '../../assets/images/novidades.png'

export default function Novidades() {


    return (
        <div id="novidades" className=' lg:px-20 py-20 px-10 '>
            <div data-aos="fade-up">
                <Titulo titulo="Novidades" />
            </div>
            <div className='flex w-full justify-center items-start gap-4  mt-20'>
                <div data-aos="fade-up" className=' lg:w-2/4 hidden lg:flex justify-center
                     items-center'>
                    <div className='bg-[#F7F1FE] p-20 rounded-lg w-[600px] '>
                        <img src={ImagemNovidades} width={600} alt='novidades-imagem' />

                    </div>
                </div>
                <div data-aos="fade-up" className="join  join-vertical rounded-none w-full lg:w-2/4">
                    <div className="collapse collapse-arrow join-item border-t border-b border-gray-300">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">
                            O PrecificaMais está disponível apenas para Android?
                        </div>
                        <div className="collapse-content">
                            <p>Por enquanto, sim. No entanto, temos boas notícias! Estamos trabalhando em uma versão para iOS, que será lançada em breve. Inscreva-se em nossa newsletter para receber as últimas novidades sobre o lançamento.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow join-item border-t border-b border-gray-300">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">
                            O PrecificaMais possui um sistema WEB ?
                        </div>
                        <div className="collapse-content">
                            <p>Não, mas estamos planejando realizar o lançamento de um versão WEB do aplicativo em atualizações futuras. </p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow join-item border-t  border-gray-300">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">
                            O PrecificaMais receberá novas funcionalidades no futuro ?
                        </div>
                        <div className="collapse-content">
                            <p>Sim, estamos trabalhando em novas atualizações para extender funcionalidades atuais do PrecificaMais</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow join-item border-t  border-gray-300">
                        <input type="radio" name="my-accordion-4" />
                        <div className="collapse-title text-xl font-medium">
                            Onde posso instalar o aplicativo do PrecificaMais ?
                        </div>
                        <div className="collapse-content">
                            <p>Você pode instalar o aplicativo através do seguinte link <a href='https://play.google.com/store/apps/details?id=com.fsa.artesanato_app'>Google Play</a>. Além disso futuramente será lançada a versão iOS do PrecificaMais. </p>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    )
}
