import React, { useRef } from "react";
import Logo from "../../assets/images/precificaMais-logo.png";
import { ReactComponent as GooglePlay } from "../../assets/icons/google-play.svg";


// import { ReactComponent as AppleStore } from "../../assets/icons/apple.svg";

export default function Footer() {

  let anoAtual = useRef(new Date().getFullYear());

  return (
    <div style={{ color: "#181D31" }}>
      <div
        className="flex flex-col justify-between lg:flex-row p-10"
        style={{ backgroundColor: "#973DF2" }}
      >
        <div className="w-full lg:w-3/5 p-5">
          <img src={Logo} width="200px" alt="Logo" />
        </div>

        <div className=" w-full mt-10 lg:mt-0 lg:w-1/5 pl-0 lg:pl-5">

          <ul className="mt-2 text-white flex flex-row gap-4">
            <li>
              <a href="#inicio">Ínicio</a>
            </li>
            <li>
              <a href="#sobre">Sobre</a>
            </li>
            <li>
              <a href="#recurso">Recursos</a>
            </li>
            <li>
              <a href="#preco">Preços</a>
            </li>
          </ul>
          
          <a  href="https://play.google.com/store/apps/details?id=com.fsa.artesanato_app" target="_blank" className="bg-white flex w-full justify-center items-center text-black rounded-lg p-3 mt-5">
            <GooglePlay transform="scale(2)" className="w-1/4" />
            <div className="flex flex-col w-2/3 items-start">
              <p className="text-sm">Disponível No</p>
              <h3 className="text-lg font-bold">Google Play</h3>
            </div>
          </a>
          <div>
          
          </div>
        </div>
       
          
          {/* <button className="bg-white flex mt-5 w-full justify-center items-center text-black rounded-lg p-3">
            <AppleStore transform="scale(2)" className="w-1/4" />
            <div className="flex flex-col w-3/4 items-start">
              <p className="text-sm">Disponível Na</p>
              <h3 className="text-lg font-bold">Apple Store</h3>
            </div>
          </button> */}
       
      </div>
      <div
        className="p-5 flex w-full justify-start items-center"
        style={{ backgroundColor: "#7930C2" }}
      >
        <p className="text-white w-full flex justify-center items-center text-center text-sm">
          FSA Soluções em Tecnologia — {anoAtual.current}  &#169; Todos os direitos reservados.
        </p>
      </div>
    </div>
  );
}
