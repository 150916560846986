import React, { useEffect } from "react";
import { secondColor } from "../../helpers/colors";
import Item from "./components/Item";
import recursoAnimation from "../../assets/lottie/cellphone.json";
import recursoAnimation2 from "../../assets/lottie/wallet.json";
import Lottie from "react-lottie";
import Titulo from "../Titulo";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faChartPie, faGear, faPencil, faTag, faUser } from '@fortawesome/free-solid-svg-icons'

export default function Recurso() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: recursoAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: recursoAnimation2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    AOS.init();
  }, []);
  const texto1 = "Por meio dos cards, você recebe um panorama detalhado de todas as suas realizações ao longo do mês.";
  const texto2 = "Tenha o controle total sobre seus materiais: crie, edite e remova itens, personalizando seus produtos de acordo com os materiais cadastrados.";
  const texto3 = "Registre seus clientes e simplifique o processo de criação de orçamentos, utilizando esses registros de forma eficiente.";
  const texto4 = "Otimize o registro dos seus produtos com nosso método de precificação. Explore os materiais previamente cadastrados e todas as opções que disponibilizamos para aprimorar sua experiência de criação de forma excepcional.";
  const texto5 = "Produza orçamentos com agilidade e eficiência, e obtenha a flexibilidade de baixá-los em formato de PDF.";
  const texto6 = "Personalize a sua conta de acordo com suas necessidades: configure o seu perfil de usuário, defina sua taxa de mão de obra, registre seus gastos mensais e estabeleça descontos com base na quantidade de unidades.";
  return (
    <div id="recurso">
      <div
        className="pr-20 pl-20 pb-20 bg-[#F7F1FE]"
        style={{  minHeight: "500px" }}
      >
        <div className="w-full  flex flex-col justify-center items-center">
          <div data-aos="fade-up">
            <Titulo titulo="Recursos" />
          </div>
          <div className="flex mt-10">
            <div
              data-aos="fade-right"
              className="w-2/4 lg:flex justify-center items-center hidden "
            >
              <Lottie options={defaultOptions} height={450} width={450} />
            </div>
            <ul data-aos="fade-up" className="w-full lg:w-2/4">
              <Item text={texto1} title="Detalhamento das suas criações" icon={<FontAwesomeIcon color="white" size={"2x"} icon={faChartPie} />} />
              <Item text={texto2} title="Cadastro de materiais" icon={<FontAwesomeIcon color="white" size={"2x"} icon={faPencil} />} />
              <Item text={texto3} title="Cadastro de clientes" icon={<FontAwesomeIcon color="white" size={"2x"} icon={faUser} />} />
            </ul>
          </div>
          <div className="flex mt-10">
            <ul data-aos="fade-up" className="w-full lg:w-2/4">
              <Item text={texto4} title="Cadastro de produtos" icon={<FontAwesomeIcon color="white" size={"2x"} icon={faCartShopping} />} />
              <Item text={texto5} title="Produção de orçamentos" icon={<FontAwesomeIcon color="white" size={"2x"} icon={faTag} />} />
              <Item text={texto6} title="Configurações" icon={<FontAwesomeIcon color="white" size={"2x"} icon={faGear} />} />
            </ul>
            <div data-aos="fade-up" className="w-2/4  hidden lg:block">
              <Lottie options={defaultOptions2} height={400} width={400} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
