import React from "react";

export default function Botao({
  width,
  color,
  border,
  backgroundColor,
  text,
  hoverColor,
  hoverText,
  onClick
}) {
  return (
    <button  onClick={onClick}     
      className={`p-3  transition-all duration-300 rounded border ${border} font-bold mt-5 ${color} ${width} ${backgroundColor} ${hoverColor} ${hoverText}`}
    >
      {text}
    </button>
  );
}
