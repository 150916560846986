import React, { useEffect } from "react";
import Titulo from "../Titulo";
import Card from "./components/Card";
import "./Preco.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Preco() {
  const caracteristicas = [
    "Configuração de custos fixos",
    "Configuração do valor da mão de obra",
    "Cadastro de Materiais",
    "Cadastro de Clientes",
    "Cadastro de Produtos",
    "Criação de Orçamentos",
    "Download des orçamentos no formato PDF",
  ];
  const caracteristicasPremium = [
    "Em breve",
    "Em breve",
    "Em breve",
    "Em breve",
    "Em breve",
    "Em breve",
    "Em breve",
    
  ];
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="preco" style={{ minHeight: "1000px" }}>
      <div data-aos="fade-up">
        <Titulo titulo="Preços" />
      </div>
      <div className="flex flex-col justify-between lg:flex-row p-20 gap-5">
        <Card
          fade="fade-up"
          titulo="Básico"
          texto="Esse plano é essencial para você começar o seu negócio !"
          preco="R$ 0,00"
          caracteristicas={caracteristicas}
          textoBotao="Download"
        />
        <Card
          fade="fade-up"
          titulo="Profissional"
          texto="Esse plano é essencial para você  escalar o seu negócio !"
          preco="Em Breve"
          caracteristicas={caracteristicasPremium}
          textoBotao="Em Breve"
        />
        <Card
          fade="fade-up"
          titulo="Profissional Anual"
          texto="Esse plano é essencial para você  escalar o seu negócio !"
          preco="Em Breve"
          caracteristicas={caracteristicasPremium}
          textoBotao="Em Breve"
        />
      </div>
    </div>
  );
}
