import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SobreImagem from '../../assets/images/sobre.png'
import { primaryColor } from "../../helpers/colors";

export default function Sobre() {

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="sobre" className="my-10">
      <div className="w-full lg:p-0 py-20 px-20 gap-10 flex justify-center items-center ">
        <div data-aos="fade-up" className="w-2/4 hidden lg:flex  justify-center items-center ">
          <img src={SobreImagem} alt="Sobre" style={{width:"60%"}}  />
        </div>
        <div data-aos="fade-up" className="w-full mt-10 lg:mt-0 lg:w-2/4">
          <div className="w-full lg:w-3/4">
            <h1 className={`font-bold text-4xl mb-2 text-[${primaryColor}]`}>O Aplicativo que chegou para revolucionar sua precificação</h1>
            <p className="text-justify mt-10 text-gray-600 text-lg">
              O PrecificaMais é um aplicativo essencial para microempresários e microempreendedores. Ele facilita a precificação de produtos, calcula automaticamente os preços, considerando custos de materiais, mão de obra e custos fixos. Além disso, oferece descontos personalizáveis com base na quantidade e a opção de adicionar materiais extras. Também auxilia no gerenciamento de clientes, tornando a gestão de preços mais eficiente e lucrativa.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
