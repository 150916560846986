import React from 'react'
import Botao from '../Botao'
import { ToastContainer, toast } from 'react-toastify';

export default function Download() {
    return (
        <div className='lg:m-20 mx-10 my-40 bg-white flex justify-center'>
            <ToastContainer />

            <div data-aos="fade-up" className='bg-primary  w-full lg:w-3/4 flex flex-col justify-center items-center text-white text-center rounded-lg px-20 py-16'>
                <h1 className='font-bold text-5xl'>Junte-se a nós !</h1>
                <p className='text-sm mt-4 w-full lg:w-2/4'>Descubra uma maneira mais inteligente de precificar seus produtos. Com nosso aplicativo de precificação, você terá o poder de otimizar seus preços e aumentar seus lucros. Não perca mais oportunidades, junte-se a nós hoje e leve sua estratégia de precificação para o próximo nível. </p>
                <a data-aos="fade-up" href="https://play.google.com/store/apps/details?id=com.fsa.artesanato_app" target="_blank">
                    <Botao text={"Android"} color="bg-white text-primary" width='w-40' /></a>
                <div data-aos="fade-up">
                    <Botao onClick={() => toast.warning("O Aplicativo em iOS ainda não está disponível")} text={"iOS"} color="bg-black border-none text-white" width='w-40' />
                </div>

            </div>

        </div>
    )
}
