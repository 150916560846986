import React, { useEffect, useState } from 'react'
import Titulo from '../Titulo'
import Tela1 from '../../assets/images/Materiais.png'
import Tela2 from '../../assets/images/Clientes.png'
import Tela3 from '../../assets/images/Produtos.png'
import Tela4 from '../../assets/images/Orcamento.png'

export default function Demonstrativo() {

  const telas = [{ id: 0, nome: "Materiais", texto: "O PrecificaMais oferece uma experiência de controle sem igual quando se trata dos seus materiais. Com ele, você pode criar, editar e remover itens de forma intuitiva, personalizando seus produtos de acordo com os materiais cadastrados. Essa ferramenta simplifica a gestão de todos os seus materiais, permitindo que você mantenha o controle total sobre o que você possui. Seja para organizar seus materiais de escritório, projetos de artesanato ou inventariar seus ativos de negócios, este aplicativo é a solução ideal para garantir que você esteja sempre no comando. Com uma interface amigável e recursos poderosos, a gestão de materiais nunca foi tão fácil e eficiente.", imagem: Tela1 }, { id: 1, nome: "Clientes", texto: "O PrecificaMais proporciona a você a capacidade de registrar seus clientes de maneira descomplicada, ao mesmo tempo em que simplifica o processo de criação de orçamentos, tornando-o mais eficiente e eficaz. Com a funcionalidade de registro de clientes, você pode armazenar informações essenciais sobre seus clientes, tornando mais fácil manter um histórico confiável de suas interações e necessidades.", imagem: Tela2 }, { id: 2, nome: "Produtos", texto: "O PrecificaMais oferece uma abordagem otimizada para o registro dos seus produtos, tornando a tarefa de gerenciar suas informações de produtos mais eficaz e simples. Com o nosso método de precificação, você pode explorar os materiais previamente cadastrados, bem como todas as opções disponíveis para aprimorar sua experiência de criação de forma excepcional.", imagem: Tela3 }, { id: 3, nome: "Orçamentos", texto: "O PrecificaMais simplifica o processo de produção de orçamentos, oferecendo agilidade e eficiência excepcionais. Com apenas alguns cliques, você pode criar orçamentos precisos e personalizados para atender às necessidades dos seus clientes de forma rápida e eficaz.", imagem: Tela4 },];

  const [telaAtual, setTelaAtual] = useState(telas[0]);

  useEffect(() => {
    setTimeout(() => {

      let numero = telaAtual.id;

      if (numero >= telas.length - 1) {
        numero = 0;
      } else {
        numero += 1;
      }

      setTelaAtual(telas[numero]);

    }, 10000);
  }, [telaAtual])

  return (
    <div id="precifica" className=' py-20 px-10 lg:p-20'>
      <div data-aos="fade-up">
        <Titulo titulo="PrecificaMais" />

      </div>
      <div className='mt-20 gap-16 flex justify-center items-center'>
        <div data-aos="fade-up" className='hidden lg:flex w-2/4 p-20'>
          <img src={telaAtual.imagem} alt={telaAtual.nome} />
        </div>
        <div data-aos="fade-up" className='w-full lg:w-2/4'>
          <div className='w-full lg:w-3/4 text-justify'>
            <h1 className='font-bold text-4xl mb-4'>{telaAtual.nome}</h1>
            <p>{telaAtual.texto}</p>
          </div>
        </div>
      </div>

    </div>
  )
}
