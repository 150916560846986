import React, { useEffect } from "react";
import "./Card.css";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import Botao from "../../../Botao";
import AOS from "aos";
import "aos/dist/aos.css";
export default function Card({
  titulo,
  texto,
  caracteristicas,
  preco,
  textoBotao,
  fade,
}) {
  const green = "#008000";
  const size = "scale(1.5)";
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos={fade} className=" card p-10 lg:p-20 flex flex-col gap-5">
      <h2 className="font-bold text-2xl">{titulo}</h2>
      <p className="text-gray-600 text-sm">{texto}</p>
      <h2 className="font-bold text-2xl">{preco}</h2>
      <ul className="flex flex-col gap-2">
        {caracteristicas.map((caracteristica, index) => (
          <li className="flex items-center gap-2 text-gray-600" key={index}>
            <Check transform={size} color={green} />
            {caracteristica}
          </li>
        ))}
      </ul>
    <a href="https://play.google.com/store/apps/details?id=com.fsa.artesanato_app" target="_blank">
      <Botao
        backgroundColor="bg-trasparent"
        border="border-[#973DF2]"
        color="text-[#973DF2]"
        hoverColor="hover:bg-[#973DF2]"
        hoverText="hover:text-white"
        text={textoBotao}
        width="w-full"
        
      /></a>
    </div>
  );
}
