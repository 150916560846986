import React, { useState } from 'react'
import Botao from '../Botao'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import NewsLetterImagem from '../../assets/images/newsletter.png';

export default function Newsletter() {

    const [email, setEmail] = useState("");


    function cadastrarEmail() {

        let dados = {
            email: email
        };

        axios.post("https://precifica.fsasolucoes.com.br/api.php/records/newsletter", dados).then((res) => {
            toast.success("Inscrição realizada com sucesso");
        }).catch((e) => {
            toast.error("O E-mail já foi cadastrado");

        });

        setEmail("");
    }
    return (
        <div className='px-10 py-20 lg:p-20 flex justify-center items-center'>
            <ToastContainer />

            <div data-aos="fade-up" className='bg-[#F0E4FD]  w-full lg:w-3/4 rounded-lg flex justify-center items-center px-20 py-16'>
                <div className='w-full lg:w-2/4 flex flex-col gap-4'>
                    <h1 className='font-bold text-3xl'>Se inscreva para receber novidades do PrecificaMais</h1>
                    <p className='text-sm'>Não perca nossas últimas atualizações! Inscreva-se agora para receber nossas novidades por e-mail</p>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="E-mail" className="w-full p-4 border border-gray-200 rounded outline-none" />
                    <Botao onClick={() => cadastrarEmail()} text="Se Inscrever" hoverText="hover:underline" />
                </div>
                <div className='w-2/4 hidden lg:flex justify-center items-center'>
                    <img src={NewsLetterImagem} alt="newsletter-imagem" width={350}/>
                </div>
            </div>
        </div>
    )
}
