import React from 'react'
import { primaryColor } from '../../../../helpers/colors'

export default function Item({ text, title, icon }) {
    return (
        <li className='flex gap-5 mt-4 mb-4'>
            <div className={`rounded-lg  w-20 h-20 justify-center items-center flex`} style={{ backgroundColor: primaryColor }}>
                {icon}
            </div>
            <div className='w-full'>
                <h1 className={`font-bold text-lg text-[${primaryColor}]`}>{title}</h1>
                <p className='text-justify font-light text-gray-700'>{text}</p>
            </div>

        </li>
    )
}
