import React, { useEffect } from "react";
import InicioImagem from "../../assets/images/inicio.png";
import Botao from "../Botao";
import AOS from "aos";
import "aos/dist/aos.css";
export default function Inicio() {


  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="inicio">
      <div>

        <div
          className="flex inicioOnda items-start p-10 pr-20 pl-20  m-0 lg:min-h-[1000px] min-h-[900px]"
        >
          <div className="flex flex-col w-full lg:w-2/4 justify-center items-center mt-40">
            <div
              data-aos="fade-up"
              className="w-full lg:w-3/4 flex flex-col justify-center items-start"
            >
              <h1 className="font-bold text-5xl mb-4">
                Simplifique sua composição de preços e eleve seus negócios com o PrecificaMais.
              </h1>
              <p className="text-justify text-sm mt-4 mb-4">
                O PrecificaMais é um aplicativo de precificação, projetado para facilitar e otimizar o processo de precificação de produtos e serviços !
              </p>
              <a href="https://play.google.com/store/apps/details?id=com.fsa.artesanato_app" target="_blank">
                <Botao
                  backgroundColor="bg-white"
                  color="text-[#973DF2]"
                  hoverColor="hover:bg-transparent"
                  hoverText="hover:text-white"
                  text="Junte-se a Nós"
                  width="w-40"
                />
              </a>
            </div>
          </div>
          <div className="lg:flex hidden flex-col w-2/4 justify-center items-center ">
            <img src={InicioImagem} alt="inicio" className="relative w-[80%] " />
          </div>
        </div>
      </div>
    </div>

  );
}
